import OSS from 'ali-oss';
import store from "@/store";
import newApi from "@/utils/newApi";

let stsCredentials = null;

async function isSTSCredentialsValid() {
    if (!stsCredentials) {
        return false;
    }
    const expirationTimestamp = new Date(stsCredentials.Expiration).getTime();
    const currentTimestamp = Date.now();
    return expirationTimestamp > currentTimestamp;
}

async function getSTSCredentials() {
    try {
        const response = await newApi.getStsAuth({});
        stsCredentials = response.data;
        return stsCredentials;
    } catch (error) {
        console.error('Error getting STS credentials:', error);
        return null;
    }
}

const Oss = {
    get_suffix(filename) {
        const pos = filename.lastIndexOf('.');
        let suffix = '';
        if (pos !== -1) {
            suffix = filename.substring(pos);
        }
        return suffix;
    },
    get_dir() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}/${month}/${day}/`;
    },
    random_string(len) {
        len = len || 32;
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var maxPos = chars.length;
        var pwd = '';
        for (var i = 0; i < len; i++) {
            pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
    async uploadToOSS(file, fileHash,progressCallBack=null) {
        if (!await isSTSCredentialsValid()) {
            await getSTSCredentials();
        }
        if (stsCredentials) {
            var client = new OSS({
                region: 'oss-cn-beijing',
                stsToken: stsCredentials.securityToken,
                accessKeyId: stsCredentials.accessKeyId,
                accessKeySecret: stsCredentials.accessKeySecret,
                bucket: 'baigongbao',
            });
            try {
                const name = this.get_dir() + this.random_string(10) + this.get_suffix(file.name);
                await client.multipartUpload(name, file, {
                    progress: function (p) {
                        if (progressCallBack && typeof(progressCallBack) == "function"){
                            progressCallBack(parseInt(p * 100))
                        }else{
                            store.commit('set_upload_info', {
                                name: name,
                                file_name: file.name,
                                percent: parseInt(p * 100),
                            });
                        }
                    }
                });

                let data = {};
                data.name = file.name;
                data.url = name;
                data.hash = fileHash;

                const result = await newApi.ossNotify(data);
                // console.log(result);

                if (result.isSuccess == 1) {
                    data.url = store.state.ossUrl+name;
                    return data;
                } else {
                    await client.delete(name);
                    return false;
                }
            } catch (error) {
                console.error('Upload error:', error);
            }
        }
    }

};


export default Oss;
